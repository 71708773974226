<template>
  <div>
    <b-card v-if="karmaRequest">
      <b-row>
        <!-- User Info: Left col -->
        <b-col class="d-flex justify-content-between flex-column md-6">
          <b-row class="align-items-center mb-2">
            <b-col cols="12" md="2" v-if="false">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-secondary"
                class=""
                @click="$router.go(-1)"
              >
                <feather-icon icon="ArrowLeftIcon" class="mr-25" />
                <span>List</span>
              </b-button>
            </b-col>
            <b-col cols="12" md="6">
              <h4>Karma Request Details:</h4>
            </b-col>
            <b-col
              v-if="
                karmaRequest.karma_program &&
                karmaRequest.karma_program.admin_ids.includes($cookies.get('userData').hashid)
              "
              cols="6"
              md="4"
              class="text-right"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="info"
                class="btn-icon"
                @click="publish()"
                v-if="!karmaRequest.is_published && karmaRequest.status"
              >
                <feather-icon icon="GlobeIcon" />
                {{
                  karmaRequest.status == "approved"
                    ? "Publish "
                    : "Approved to Publish"
                }}
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-row>
                <b-col md="4" class="pb-50 font-weight-bold">
                  Requested For
                </b-col>
                <b-col md="8" class="pb-50">
                  <b-link
                    v-if="$can('employee_show')"
                    :to="{
                      name: 'view-employee',
                      params: { id: karmaRequest.requested_by_id },
                    }"
                    class="font-weight-bold d-block text-nowrap text-dark"
                  >
                    <b-media v-if="karmaRequest.requested_for">
                      <template #aside>
                        <b-avatar :src="karmaRequest.requested_for.avatar" />
                      </template>
                      <h6 class="mb-0">
                        {{ karmaRequest.requested_for.name }}
                      </h6>
                      <p class="mb-0">
                        {{ karmaRequest.requested_for.designation }}
                      </p>
                    </b-media>
                  </b-link>
                  <b-media v-else>
                    <template #aside>
                      <b-avatar :src="karmaRequest.requested_for.avatar" />
                    </template>
                    <h6 class="mb-0">{{ karmaRequest.requested_for.name }}</h6>
                    <p class="mb-0">
                      {{ karmaRequest.requested_for.designation }}
                    </p>
                  </b-media>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="6">
              <b-row>
                <b-col md="4" class="pb-50 font-weight-bold">
                  Requested By
                </b-col>
                <b-col md="8" class="pb-50">
                  <b-link
                    v-if="$can('employee_show')"
                    :to="{
                      name: 'view-employee',
                      params: { id: karmaRequest.requested_by_id },
                    }"
                    class="font-weight-bold d-block text-nowrap text-dark"
                  >
                    <b-media
                      v-if="
                        karmaRequest.requested_by_id &&
                        karmaRequest.requested_by
                      "
                    >
                      <template #aside>
                        <b-avatar :src="karmaRequest.requested_by.avatar" />
                      </template>
                      <h6 class="mb-0">{{ karmaRequest.requested_by.name }}</h6>
                      <p class="mb-0">
                        {{ karmaRequest.requested_by.designation }}
                      </p>
                    </b-media>
                  </b-link>
                  <b-media v-else-if="karmaRequest.requested_by">
                    <template #aside>
                      <b-avatar :src="karmaRequest.requested_by.avatar" />
                    </template>
                    <h6 class="mb-0">{{ karmaRequest.requested_by.name }}</h6>
                    <p class="mb-0">
                      {{ karmaRequest.requested_by.designation }}
                    </p>
                  </b-media>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="6" v-if="karmaRequest.karma_catalog">
              <b-row>
                <b-col md="4" class="pb-50 font-weight-bold">
                  Karma Catalog
                </b-col>
                <b-col md="8" class="pb-50">
                  <b-link
                    :to="{
                      name: 'view-karma-catalog',
                      params: { id: karmaRequest.karma_catalog.hashid },
                    }"
                    class="font-weight-bold d-block"
                  >
                    {{ karmaRequest.karma_catalog.karma_name }}
                  </b-link>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="6">
              <b-row>
                <b-col md="4" class="pb-50 font-weight-bold">
                  Karma Points
                </b-col>
                <b-col md="8" class="pb-50">
                  {{ karmaRequest.karma_point }}
                </b-col>
              </b-row>
            </b-col>
            <b-col md="6" v-if="karmaRequest && karmaRequest.karma_program">
              <b-row>
                <b-col md="4" class="pb-50 font-weight-bold">
                  Karma Program
                </b-col>
                <b-col md="8" class="pb-50">
                  <b-link
                    :to="{
                      name: 'view-karma-program',
                      params: { id: karmaRequest.karma_program.hashid },
                    }"
                    class="font-weight-bold d-block"
                  >
                    {{ karmaRequest.karma_program.name }}
                  </b-link>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="6" v-if="karmaRequest.status">
              <b-row>
                <b-col md="4" class="pb-50 font-weight-bold">
                  Karma Request Status
                </b-col>
                <b-col md="8" class="pb-50 text-capitalize">
                  {{ karmaRequest.status.replace(/_/g, " ") }}
                </b-col>
              </b-row>
            </b-col>
            <b-col md="6" v-if="karmaRequest.link">
              <b-row>
                <b-col md="4" class="pb-50 font-weight-bold"> Link </b-col>
                <b-col md="8" class="pb-50">
                  <b-link
                    @click="openWindow(karmaRequest.link)"
                    class="font-weight-bold d-block"
                  >
                    {{ karmaRequest.link | str_limit(50) }}
                  </b-link>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="6">
              <b-row>
                <b-col md="4" class="pb-50 font-weight-bold">
                  Karma Date
                </b-col>
                <b-col md="8" class="pb-50">
                  {{ karmaRequest.date | formatDate }}
                </b-col>
              </b-row>
            </b-col>
            <b-col md="6">
              <b-row>
                <b-col md="4" class="pb-50 font-weight-bold">
                  Requested at
                </b-col>
                <b-col md="8" class="pb-50">
                  {{ karmaRequest.created_at | formatDate }}
                </b-col>
              </b-row>
            </b-col>
            <b-col md="12">
              <b-row>
                <b-col md="2" class="pb-50 font-weight-bold"> Notes </b-col>
                <b-col md="10" class="pb-50">
                  {{ karmaRequest.notes }}
                </b-col>
              </b-row>
            </b-col>

            <b-col md="6" v-if="karmaRequest.karma_catalog">
              <b-row>
                <b-col md="4" class="pb-50 font-weight-bold">
                  Karma Request Auditors
                </b-col>
                <b-col md="8" class="pb-50 text-capitalize">
                  {{ karmaRequest.karma_catalog.auditors.toString() }}
                </b-col>
              </b-row>
            </b-col>
            <b-col md="6" v-if="karmaRequest.approved_by_ids">
              <b-row>
                <b-col md="4" class="pb-50 font-weight-bold">
                  Reviewed By
                </b-col>
                <b-col md="8" class="pb-50 text-capitalize">
                  <p
                    v-if="
                      karmaRequest.approved_by_ids.admin &&
                      karmaRequest.approved_by_ids.admin.status == 'approved'
                    "
                  >
                    Approved By Admin({{
                      karmaRequest.approved_by_ids.admin.user.name
                    }})
                  </p>
                  <p
                    v-if="
                      karmaRequest.approved_by_ids.owner &&
                      karmaRequest.approved_by_ids.owner.status == 'approved'
                    "
                  >
                    Approved By Category Owner({{
                      karmaRequest.approved_by_ids.owner.user.name
                    }})
                  </p>
                  <p
                    v-if="
                      karmaRequest.approved_by_ids.reporting_manager &&
                      karmaRequest.approved_by_ids.reporting_manager.user &&
                      karmaRequest.approved_by_ids.reporting_manager.status ==
                        'approved'
                    "
                  >
                    Approved By Reporting Manager({{
                      karmaRequest.approved_by_ids.reporting_manager.user.name
                    }})
                  </p>
                  <p
                    v-if="
                      karmaRequest.approved_by_ids.admin &&
                      karmaRequest.approved_by_ids.admin.status == 'rejected'
                    "
                  >
                    Rejected By Admin({{
                      karmaRequest.approved_by_ids.admin.user.name
                    }})
                  </p>
                  <p
                    v-if="
                      karmaRequest.approved_by_ids.owner &&
                      karmaRequest.approved_by_ids.owner.status == 'rejected'
                    "
                  >
                    Rejected By Category Owner({{
                      karmaRequest.approved_by_ids.owner.user.name
                    }})
                  </p>
                  <p
                    v-if="
                      karmaRequest.approved_by_ids.reporting_manager &&
                      karmaRequest.approved_by_ids.reporting_manager.user &&
                      karmaRequest.approved_by_ids.reporting_manager.status ==
                        'rejected'
                    "
                  >
                    Rejected By Reporting Manager({{
                      karmaRequest.approved_by_ids.reporting_manager.user.name
                    }})
                  </p>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              md="6"
              v-if="
                karmaRequest.attachments && karmaRequest.attachments.length > 0
              "
            >
              <div class="border-top pt-1">
                <b class="text-secondary">Uploaded Attachments</b>

                <div class="d-flex flex-column">
                  <div
                    v-for="(file, index) in karmaRequest.attachments"
                    :key="index"
                    class="d-flex align-items-center justify-content-between my-1"
                  >
                    <img
                      v-if="file.type.includes('image')"
                      :src="file.link"
                      class="img-fluid"
                      width="100px"
                      height="100px"
                      alt="Attachment"
                    />
                    <span
                      v-else
                      class="text-primary"
                      v-b-tooltip.hover.v-default
                      :title="file.name"
                    >
                      {{ file.name | str_limit(40) }}
                    </span>
                    <div>
                      <feather-icon
                        v-b-tooltip.hover.top="'View Attachment'"
                        size="20"
                        class="btn-link cursor-pointer ml-1"
                        icon="EyeIcon"
                        @click.stop.prevent="openWindow(file.link)"
                      />

                      <feather-icon
                        v-b-tooltip.hover.top="'Remove Attachment'"
                        v-if="$can('karma_request_edit')"
                        size="20"
                        class="btn-link cursor-pointer ml-1 text-danger"
                        icon="TrashIcon"
                        @click.stop.prevent="confirmDeleteRecord(file.uuid)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-for="(data, index) in customFields" v-bind:key="index" md="6">
          <b-row>
            <b-col md="6" class="pb-50 font-weight-bold">
              {{ data.label }}
            </b-col>
            <b-col md="6" class="pb-50">
              {{ karmaRequest.get_customfields_data["field_" + data.id] }}
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <PostEdit
        :item-id.sync="postId"
        :sidebar-edit-active.sync="sidebarEditActive"
        :employeeListOptions.sync="employeeListOptions"
        :customFields.sync="postCustomFields"
      />
    </b-card>
    <b-row
      v-if="
        karmaRequest.karma_catalog &&
        karmaRequest.karma_catalog.auditors &&
        karmaRequest.karma_catalog.auditors.includes('admin')
      "
    >
      <b-col
        v-if="
          karmaRequest.karma_program &&
          karmaRequest.karma_program.admin_ids.includes($cookies.get('userData').hashid)
        "
        cols="12"
        xl="12"
        lg="12"
        md="12"
      >
        <b-card title="Review Karma Request (Admin)">
          <b-form autocomplete="off" id="karma-request-review-form-admin" class="">
            <b-row>
              <b-col md="4">
                <b-form-group
                  label="Reviewer Reason"
                  label-for="reviewer_reason"
                >
                  <b-form-textarea
                    id="reviewer_reason"
                    :value="karmaRequest.approved_by_ids.admin.review"
                    name="review"
                    placeholder="Write Review for this application"
                    rows="3"
                  />
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="Karma Point" label-for="karma-point">
                  <b-form-input
                    id="karma-point"
                    v-on:keypress="NumbersOnly"
                    :value="karmaRequest.karma_point"
                    type="number"
                    name="karma_point"
                    placeholder="Karma Point"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4" v-if="karmaRequest.approved_by_ids">
                <b-form-group label="Request Status" label-for="request-status">
                  <b-button
                    class="ml-5 mt-2"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    @click="saveReview('approved', 'admin')"
                    v-if="
                      karmaRequest.approved_by_ids.admin &&
                      karmaRequest.approved_by_ids.admin.status != 'approved'
                    "
                  >
                    <feather-icon icon="CheckIcon" />
                    Approve
                  </b-button>
                  <b-button
                    class="ml-5 mt-2"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="danger"
                    @click="saveReview('rejected', 'admin')"
                    v-if="
                      karmaRequest.approved_by_ids.admin &&
                      karmaRequest.approved_by_ids.admin.status != 'rejected'
                    "
                  >
                    <feather-icon icon="XIcon" />
                    Reject
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
    <b-row
      v-if="
        karmaRequest.karma_catalog &&
        karmaRequest.karma_catalog.auditors &&
        karmaRequest.karma_catalog.auditors.includes('owner')
      "
    >
      <b-col v-if="employee_option.length" cols="12" xl="12" lg="12" md="12">
        <b-card title="Review Karma Request (Owner)">
          <b-form autocomplete="off" id="karma-request-review-form-owner" class="">
            <b-row>
              <b-col md="4">
                <b-form-group
                  label="Reviewer Reason"
                  label-for="reviewer_reason"
                >
                  <b-form-textarea
                    id="reviewer_reason"
                    :value="karmaRequest.approved_by_ids.owner.review"
                    name="review"
                    placeholder="Write Review for this application"
                    rows="3"
                  >
                  </b-form-textarea>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="Karma Point" label-for="karma-point">
                  <b-form-input
                    id="karma-point"
                    v-on:keypress="NumbersOnly"
                    :value="karmaRequest.karma_point"
                    type="number"
                    name="karma_point"
                    placeholder="Karma Point"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Request Status" label-for="request-status">
                  <b-button
                    class="ml-5 mt-2"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    @click="saveReview('approved', 'owner')"
                    v-if="
                      karmaRequest.approved_by_ids.owner &&
                      karmaRequest.approved_by_ids.owner.status != 'approved'
                    "
                  >
                    <feather-icon icon="CheckIcon" />
                    Approve
                  </b-button>
                  <b-button
                    class="ml-5 mt-2"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="danger"
                    @click="saveReview('rejected', 'owner')"
                    v-if="
                      karmaRequest.approved_by_ids.owner &&
                      karmaRequest.approved_by_ids.owner.status != 'rejected'
                    "
                  >
                    <feather-icon icon="XIcon" />
                    Reject
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
    <b-row
      v-if="
        karmaRequest.karma_catalog &&
        karmaRequest.karma_catalog.auditors.includes('reporting_manager')
      "
    >
      <b-col
        v-if="
          karmaRequest.approved_by_ids &&
          karmaRequest.approved_by_ids.reporting_manager &&
          karmaRequest.approved_by_ids.reporting_manager.user &&
          karmaRequest.approved_by_ids.reporting_manager.user.hashid ==
            $cookies.get('userData').hashid
        "
        cols="12"
        xl="12"
        lg="12"
        md="12"
      >
        <b-card title="Review Karma Request (Reporting Manager)">
          <b-form autocomplete="off" id="karma-request-review-form-reporting_manager" class="">
            <b-row>
              <b-col md="4">
                <b-form-group
                  label="Reviewer Reason"
                  label-for="reviewer_reason"
                >
                  <b-form-textarea
                    id="reviewer_reason"
                    :value="
                      karmaRequest.approved_by_ids.reporting_manager.review
                    "
                    name="review"
                    placeholder="Write Review for this application"
                    rows="3"
                  />
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="Karma Point" label-for="karma-point">
                  <b-form-input
                    id="karma-point"
                    v-on:keypress="NumbersOnly"
                    :value="karmaRequest.karma_point"
                    type="number"
                    name="karma_point"
                    placeholder="Karma Point"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Request Status" label-for="request-status">
                  <b-button
                    class="ml-5 mt-2"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    v-if="
                      karmaRequest.approved_by_ids.reporting_manager &&
                      karmaRequest.approved_by_ids.reporting_manager.status !=
                        'approved'
                    "
                    @click="saveReview('approved', 'reporting_manager')"
                  >
                    <feather-icon icon="CheckIcon" />
                    Approve
                  </b-button>
                  <b-button
                    class="ml-5 mt-2"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="danger"
                    @click="saveReview('rejected', 'reporting_manager')"
                    v-if="
                      karmaRequest.approved_by_ids.reporting_manager &&
                      karmaRequest.approved_by_ids.reporting_manager.status !=
                        'rejected'
                    "
                  >
                    <feather-icon icon="XIcon" />
                    Reject
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BButton, VBTooltip } from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

import PostEdit from "@/views/feed/PostEdit.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { bus } from "@/main.js";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    PostEdit,
    ToastificationContent,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    customFields: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.karmaRequest = this.userData;
  },
  data() {
    return {
      karmaRequest: [],
      employee_option: [],
      userDataLocal: this.$cookies.get("userData"),
      sidebarEditActive: true,
      employeeListOptions: {},
      customFields:{},
      postCustomFields:{},
      postId: "",
      post: {},
      local_data: {
        review: "",
        status: "",
        reviewer: "",
      },
    };
  },
  created() {
    this.fetchEmployeeList();
    this.getCustomFields();
    this.getPostCustomFields();
  },
  methods: {
    NumbersOnly(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    saveReview(status, reviewer) {
      const self = this;
      const formData = new FormData(
        document.getElementById("karma-request-review-form-" + reviewer)
      );
      formData.append("status", status);
      formData.append("reviewer", reviewer);
      let data = {};
      data.url = "karma-requests/save-review/" + self.karmaRequest.hashid;
      data.params = formData;
      // data.params = {
      //   status: status,
      //   review: self.local_data.review,
      //   reviewer: reviewer,
      // };
      this.isBusy = true;

      this.$store
        .dispatch("app/store", data)
        .then((res) => {
          if (res.data.success) {
            this.fetch_user_data(this.$route.params.id);
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Review saved successfully",
                icon: "BellIcon",
                variant: "success",
              },
            });
          } else {
            this.fetch_user_data(this.$route.params.id);
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Review Update Failed",
                icon: "BellIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((error) => {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Review Update Failed",
              icon: "BellIcon",
              variant: "danger",
              text: error,
            },
          });
        });
    },
    confirmDeleteRecord(uuid) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete file.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.removeAttachment(uuid);
        }
      });
    },
    removeAttachment(uuid) {
      let data = {};
      data.url = "karma-requests/media/destroy/" + uuid;
      this.isBusy = true;
      this.$store
        .dispatch("app/delete", data)
        .then((res) => {
          this.isBusy = false;
          location.reload();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Attachment Deleted",
              icon: "BellIcon",
              variant: "success",
              text: "Attachment Deleted Successfully.",
            },
          });
        })
        .catch((error) => {
          this.isBusy = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Attachment Deletion failed.",
              icon: "BellIcon",
              variant: "danger",
            },
          });
        });
    },
    openWindow(link) {
      window.open(link);
    },
    publish() {
      const self = this;

      if (self.karmaRequest.is_published) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Unable to publish Karma Points",
            icon: "EditIcon",
            variant: "danger",
            text: "Karma Request already published.",
          },
        });
        return;
      }

      if (self.karmaRequest.status != "approved") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Unable to publish Karma Points",
            icon: "EditIcon",
            variant: "danger",
            text: "Karma Request must be approved.",
          },
        });
        return;
      }
      let data = {};
      data.url = "posts";

      data.params = {
        channel_type: "community",
        post_anonymously: false,
        feedback_type: "",
        title:
          "Successful completion of " +
          self.karmaRequest.karma_catalog.karma_name +
          " by " +
          self.karmaRequest.requested_for.name,
        body:
          "Successful completion of " +
          self.karmaRequest.karma_catalog.karma_name +
          " by " +
          self.karmaRequest.requested_for.name,
        scheduled_time: "",
        is_scheduled: false,
        is_draft: true,
        is_published: false,
        karma_hashid: self.karmaRequest.hashid,
      };

      this.isBusy = true;
      this.$store
        .dispatch("app/store", data)
        .then((res) => {
          this.isBusy = false;
          self.postId = res.data.data.hashid;
          self.post = res.data.data;

          this.$root.$emit("bv::toggle::collapse", "edit-sidebar");
          this.fetch_user_data(this.$route.params.id);
        })
        .catch((error) => {
          this.isBusy = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Post Adding Failed",
              icon: "BellIcon",
              variant: "danger",
              text: error,
            },
          });
        });
    },
    fetchEmployeeList() {
      const self = this;
      let data = {};
      data.url = "list/user-list-for-tag-names";
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          this.employeeListOptions = res.data.data;
          this.$cookies.set("employeeListOptions", this.employeeListOptions);
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "fetch Employee List failed.",
              icon: "BellIcon",
              variant: "danger",
              text: err.toString(),
            },
          });
        });
    },
    fetch_user_data(userId) {
      const self = this;
      this.$store
        .dispatch("app-karma-requests/fetchKarmaRequest", userId)
        .then((res) => {
          self.karmaRequest = res.data.data;
        })
        .catch((err) => {
          console.error(err);
          // if (err.response.status === 404) {
          //   return;
          // }
          console.error(err);
        });
    },

    getPostCustomFields() {
      const self = this;

      let data = {};
      data.url = "/posts/create";

      self.$store
        .dispatch("app/create", data)
        .then((res) => {
          this.postCustomFields = res.data;
        })
        .catch((error) => {
          if (error.response.status != 403) {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong",
                icon: "BellIcon",
                variant: "danger",
                text: error.toString(),
              },
            });
          }
        });
    },
    getCustomFields() {
      const self = this;
      self.$store
        .dispatch(
          "app-karma-requests/getCustomFields",
          self.$router.currentRoute.params.id
        )
        .then((res) => {
          this.customFields = res.data.data;
          // self.karmaRequestStatusOption = res.data.meta.status;
          self.employee_option = res.data.employee_option;
          // self.karma_program_option = res.data.karma_program_option;
          // if (self.karma_program_option.length == 1) {
          //   self.data_local.karma_program_id =
          //     self.karma_program_option[0].hashid;
          //   this.getCategories(self.karma_program_option[0].hashid);
          //   this.getChildCatalogue(self.data_local.karma_program_id);
          // }
        })
        .catch((error) => {
          console.log(error);
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Custom Fields Getting Failed",
              icon: "BellIcon",
              variant: "danger",
            },
          });
        });
    },
  },
};
</script>

<style>
</style>
